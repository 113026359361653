import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const StatusBanner = makeShortcode("StatusBanner");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-valor",
      "style": {
        "position": "relative"
      }
    }}>{`💯 Valor`}<a parentName="h1" {...{
        "href": "#-valor",
        "aria-label": " valor permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`¿Qué es el valor? En cierto sentido, esta es la pregunta fundamental. Entonces, ¿cómo podemos usar nuestra habilidadad de pensamiento basado en opuestos complementarios para desglosarlo y llegar a algunas ideas útiles?`}</p>
    <StatusBanner warning mdxType="StatusBanner">
      <p>{`💡 Haz una pausa aquí y reflexiona por ti mismo antes de seguir leyendo...`}</p>
    </StatusBanner>
    <hr></hr>
    <p>{`Podemos comenzar preguntándonos, "¿Qué destruye el valor?" Algunas sugerencias son: el desacuerdo, miedo,
engaño, violencia, ira, envidia e ineficiencia.`}</p>
    <p>{`Espero que ahora puedas comenzar a ver por qué jugar con patrones es una manera de pensar tan
valiosa y poderosa. En lugar de presuponer egoístamente que podemos llegar directamente a una respuesta
coherente, la idea es explorar el espacio de la aparente oposición. Sorprendentemente, esto da luz
sobre la pregunta original. De la misma manera que construir protocolos sin confianza significa definir
claramente qué significa hacer trampa; podemos llegar a entender mucho más sobre el valor
al considerar primero lo que no es. `}</p>
    <p>{`Si no es el desacuerdo, el miedo, el engaño y la envidia, entonces parece que la `}<strong parentName="p">{`confianza en verdades
claramente compartidas`}</strong>{` se encuentra en el centro de cómo podemos generar interacciones valiosas entre las personas.`}</p>
    <h2 {...{
      "id": "consenso",
      "style": {
        "position": "relative"
      }
    }}>{`Consenso`}<a parentName="h2" {...{
        "href": "#consenso",
        "aria-label": "consenso permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Entonces, ¿cómo creamos una verdad compartida? La respuesta simple es: `}<em parentName="p">{`a través de la ficción`}</em>{`. La gente
tiende a menospreciar la ficción, aunque en realidad es sólo la mentira la que revela la verdad. Lo que
más importa en términos de generación de valor real es la eficiencia del lenguaje que empleas para contar tu ficción.`}</p>
    <p>{`La ficción legal de la empresa resultó en una mejora de órdenes de magnitud en nuestra capacidad de
generar valor a partir de nuevos tipos de transacciones. También terminó siendo extractivo y
corrupto, porque el texto legal debe ser analizado e interpretado por humanos, que son propensos a errores
y fáciles de manipular; y porque el texto legal es, de hecho, impuesto por la amenaza de violencia
que se basa en el poder asimétrico de un estado nación.`}</p>
    <p>{`Blockchain implementa un nuevo tipo de ficción de consenso matemático. La pregunta central sigue siendo:
¿cuántas personas fiablemente pueden compartir la verdad que revela tu ficción? ya que esto define las
restricciones del tipo de valor que se puede generar con ellas.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`P: ¿Cómo podemos desarrollar interacciones valiosas entre las personas? `}</p>
        <p>{`R: Creando confianza en verdades claramente compartidas.`}</p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "narrativa",
      "style": {
        "position": "relative"
      }
    }}>{`Narrativa`}<a parentName="h2" {...{
        "href": "#narrativa",
        "aria-label": "narrativa permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Las narrativas tribales permitieron compartir la verdad entre ~150 personas. Estos se convirtieron en
mitos religiosos en la época de la revolución agrícola. No es casualidad que, concomitantemente con la
difusión de los primeros grandes mitos religiosos, se produjera el desarrollo de la escritura; cuyo uso
principal era el seguimiento de la deuda. El mito por sí solo no es suficiente para mantener unidas a
sociedades más grandes que la tribu promedio: debe combinarse con un registro compartido, construido
alrededor de símbolos sucintos y significativos.`}</p>
    <p><strong parentName="p">{`Nuestra capacidad para crear valor siempre
ha estado ligada a las formas en que contamos historias sobre y con nuestros registros compartidos`}</strong>{`. Sin embargo,
antes del feedback loop descrito en `}<a parentName="p" {...{
        "href": "/learn/module-0/trust"
      }}>{`confianza`}</a>{`, el registro lo mantenía
`}<em parentName="p">{`alguien`}</em>{`, lo que les otorga un enorme poder y significa que todos los demás tienen incentivos
para intentar manipularlos.`}</p>
    <p>{`Blockchain nos permite definir de manera sucinta nuestras verdades compartidas, `}<strong parentName="p">{`y debido a que el     registro
en sí mismo se comparte`}</strong>{` entre todos los participantes, hay un "espacio de confianza" completamente nuevo que podemos explorar,
en busca de tipos de transacciones más valiosas, imposibles dentro de las meras ficciones legales.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
    
P: Frente a la ficción legal de la empresa, ¿cuál es la ficción que revela la verdad que subyace al valor generado por blockchains?
        <p>{`R: Consenso matemático (puntos extra si dijiste "criptográficamente seguro").`}</p>
      </Card>
    </Flash>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      